import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function Member() {
  return (
    <div className="bg-white py-10">
      <div className="mx-auto flex max-w-5xl flex-col justify-between lg:flex-row">
        <div className="relative w-full flex-col  items-center lg:w-2/3">
          <div>
            <h2 className="flex justify-center px-3 pb-5 text-2xl text-gray-900">
              Apple Mechanical is a proud member of the following:
            </h2>
          </div>
          <div className="flex flex-row flex-wrap justify-center px-3 text-gray-500">
            <div className="-ml-1 mr-3">* CFIB</div>
            <div className="mr-3">* Contractors Check</div>
            <div className="mr-3">* Compliance Sync</div>
            <div className="mr-3">* Community Source Solutions</div>
            <div className="mr-3">* Compliance Works</div>
            <div className="mr-3">* Quad-Real</div>
            <div className="mr-3">* College of Trades</div>
            <div className="mr-3">* Ontario Waterworks Assoc</div>
            <div className="mr-3">* American Backflow Prevention Assoc</div>
            <div className="mr-3">* Ontario Safety Compliant</div>
            <div className="mr-3">* Ontario One Call</div>
            <div className="mr-3">* Fully Insured & WSIB compliant</div>
          </div>
        </div>
        <div className="relative mx-auto flex w-full pt-10 lg:w-1/3 lg:pt-0">
          <StaticImage
            src="../images/best-excavation-service.jpg"
            quality={95}
            width={200}
            formats={['AUTO', 'WEBP']}
            className="object-fit mx-auto "
            alt="Award Winning Best Excavation Service"
          />
        </div>
      </div>
    </div>
  );
}

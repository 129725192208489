import { MailIcon } from '@heroicons/react/outline';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const offices = [
  { id: 1, city: 'GTA', phone: ['905-791-1611'] },
  { id: 2, city: 'Orangeville', phone: ['519-341-0011  '] },
  { id: 3, city: 'Collingwood', phone: ['705-915-1611 '] },
];
export default function Contact() {
  return (
    <div className="relative bg-white">
      <div className="h-96  bg-indigo-600 sm:h-72 md:absolute md:inset-y-24 md:left-0 md:w-1/2 lg:inset-y-0 lg:h-full">
        <StaticImage
          src="../images/heat-exchange.png"
          className="h-full w-full object-cover"
          alt="Commercial Heat Exchange Plumbing"
        />
      </div>
      <div className="relative mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-24">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            Get in touch
          </h2>
          <p className="mt-3 text-lg leading-6 text-gray-500">
            When you need plumbing services, trust the experts at Apple
            Mechanical. Our technicians have the skills, knowledge, and tools to
            fix most Plumbing problems on the spot.
          </p>
          <dl className="mt-8 text-base text-gray-500">
            <div>
              <dt className="sr-only">Postal address</dt>
              <dd>
                <p>1 Marconi Court, Unit #7</p>
                <p>Caledon Ontario L7E 1E2</p>
              </dd>
            </div>
            <div className="mt-10 grid grid-cols-2 gap-10 sm:grid-cols-3 lg:grid-cols-3">
              {offices.map((office) => (
                <div key={office.id}>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {office.city}
                  </h3>
                  <p className="text-warm-gray-500 mt-2 text-base">
                    {office.phone.map((line) => (
                      <span key={line} className="block">
                        {line}
                      </span>
                    ))}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-5 flex">
              <h3 className="flex text-lg font-bold text-gray-900">
                Toll Free:
              </h3>
              <dt className="sr-only">Phone number</dt>
              <dd className="flex items-end">
                <span className="ml-3">1-877-799-6446</span>
              </dd>
            </div>
            <div className="mt-3 flex">
              <h3 className="text-lg font-bold text-gray-900">
                Emergency 24/7
              </h3>
              <dt className="sr-only">Phone number</dt>
              <dd className="flex items-end">
                <span className="ml-3">1-416-609-7285</span>
              </dd>
            </div>

            <div className="mt-3">
              <dt className="sr-only">Email</dt>
              <dd className="flex">
                <MailIcon
                  className="h-6 w-6 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">info@applemech.ca</span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    // <div className="relative bg-white">
    //   <div className="absolute inset-0">
    //     <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
    //   </div>
    //   <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
    //     <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
  );
}

import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function Hero() {
  return (
    <div className="bg-white">
      <main>
        <div>
          {/* Hero card */}
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />

            <div className="mx-auto ">
              <div className="relative shadow-xl sm:overflow-hidden ">
                <div className="absolute inset-0">
                  <StaticImage
                    src="../images/mast-head.png"
                    className="h-full w-full object-cover"
                    alt="certified commercial plumbing"
                  />

                  <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" />
                </div>
                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-12 lg:px-8">
                  <div className="pb-10 text-center">
                    <StaticImage
                      src="../images/apple-mechancial-logo.png"
                      quality={95}
                      width={200}
                      formats={['AUTO', 'WEBP']}
                      className="object-fit mx-auto "
                      alt="Apple Mechanical Logo"
                    />
                  </div>
                  <h2 className="pb-4 text-center text-4xl font-bold text-white md:text-6xl">
                    <a href="/">Apple Mechanical</a>
                  </h2>
                  <h1 className="text-center text-3xl tracking-tight sm:text-5xl md:text-4xl lg:text-4xl">
                    <span className="block text-white">
                      A trusted contractor in the plumbing industry
                    </span>
                  </h1>
                  <p className="mx-auto mt-6 max-w-lg text-center text-xl text-white sm:max-w-3xl">
                    We’re open to serve you Monday to Friday from 8am to 4pm,
                    but our highly-skilled and efficient team is available to
                    provide our existing account customers with 24/7 emergency
                    service. After all, your job is our number one priority.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Logo cloud */}
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  );
}

import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function Featured() {
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-40 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../images/drain-ground.png"
            alt="Commercial Drain Plumbing Installation"
          />
        </div>
      </div>
      <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 ">
        <div className="lg:pr-8">
          <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              Hundreds of Projects
            </h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              With hundreds of projects under our belt, our plumbers have the
              experience to ensure that your project is executed to your
              expectations.
            </p>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              We support you from concept and design, all the way through to
              installation and maintenance of all interior & exterior plumbing
              systems, process piping, drainage, vents, and related equipment!
            </p>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              And, we strictly adhere to all building code standards and
              industrial safety regulations. No matter how small or large your
              project may be, we will be there in a hurry to fix it!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from 'react';
import Contact from '../components/contact';
import Featured from '../components/featured';
import Features from '../components/features';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Member from '../components/member';
import Seo from '../components/seo';
import Services from '../components/services';
import Vactor from '../components/vactor';

function Index() {
  return (
    <Layout>
      <Seo title="Trained & Certified Plumbers" />
      <Hero />

      <Services />
      <Featured />

      <Features />
      <Vactor />

      <Contact />
      <Member />
    </Layout>
  );
}

export default Index;

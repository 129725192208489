import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/outline';

const features = [
  {
    name: 'Building Stack',
    description: [
      'power flushing, snaking & cleaning',
      'installation of new stacks and clean-outs',
      'emergency backups',
      'preventative maintenance',
    ],
  },
  {
    name: 'Catch Basins',
    description: [
      'repair or replacement including stake-outs and full binder report',
      'cleaning',
      'maintenance programs',
      'video drain camera inspection and locates',
    ],
  },
  {
    name: 'Watermains',
    description: [
      'excavation for full or partial replacement',
      'including stake-outs and full binder report',
      'emergency repairs',
      'video drain camera inspection and locates',
    ],
  },

  {
    name: 'Curb Stops and Valves',
    description: [
      'entire building valve charts',
      'individual valve tagging (i.e. block shut-offs in townhouse complex)',
      'hose bib repairs, replacements & winterization',
      'meter chamber maintenance',
      'pool hut repairs, replacement & winterization',
      'replacement or repair of main shut-off valves',
      'excavation for full or partial replacement, including stake-outs and full binder report',
    ],
  },
  {
    name: 'General Repairs',
    description: [
      'commercial, industrial, and condominium ',
      'attentive to general service calls',
      'pressure reducing valves (PRVs)',
      'backwater valves',
      'all types of pumps repairs, replacement and maintenance',
      'fan coil/heat pump maintenance program',
      'condensate drain maintenance',
      'boiler and hot water tank repair and replacement',
    ],
  },
  {
    name: 'Backflow Preventers(BFP) [Licensed]',
    description: [
      'highly knowledgeable in BFP requirements per region',
      'cross connection survey',
      'annual testing',
      'obtain permit, installation, testing, inspection, and submission',
      'repairs and re-test',
    ],
  },
  {
    name: 'Excavations',
    description: [
      'replacements of catch basins',
      'replacements of curb stops',
      'replacements of meter chambers',
    ],
  },
  {
    name: 'Pipe Fitters',
    description: [
      'pipe fitting/process work',
      'sprinkler valves, new and repair',
      'factory & institutional',
      'sprinklers, new & repairs of systems',
      'factory/manufacturing installation for equipment',
    ],
  },

  {
    name: 'Risers',
    description: [
      'repair or replacement',
      'shut downs',
      'riser valve maintenance or replacement',
    ],
  },
];

export default function Services() {
  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-7xl py-16 px-4  sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-base font-semibold uppercase tracking-wide text-indigo-600">
            trust the experts
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">
            Trained & Certified Plumbers
          </p>
          <p className="mt-4 text-lg text-gray-500">
            Are you in need of plumbing installation or immediate plumbing
            repair? If so, it’s imperative to hire a plumbing company that will
            not cut corners. Apple Mechanical responds with thorough and
            immediate action.
          </p>
        </div>
        <div className="mt-12 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <div>
                <p className="ml-9 text-lg font-bold leading-6 text-gray-900">
                  {feature.name}
                </p>
              </div>
              <div className="mt-2 ml-9 text-base text-gray-500">
                {feature.description && (
                  <div className="mx-auto mt-2  text-left md:max-w-lg">
                    {feature.description.map((items, i) => (
                      <div
                        key={i}
                        className="flex md:justify-between lg:col-span-1"
                      >
                        {/* <span className="pt-1.5 pr-2 text-xs">&#9711;</span> */}
                        <div key={i} className="flex ">
                          <span>
                            <CheckCircleIcon
                              className="mt-1 mr-2 h-5 w-5 text-indigo-600"
                              aria-hidden="true"
                            />
                          </span>
                          <span className="py-1">{items}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function Vactor() {
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className=" lg:absolute lg:inset-y-52 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../images/vactor-truck.jpg"
            className="Vacuum Truck Services"
          />
        </div>
      </div>
      <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 lg:py-32">
        <div className="lg:pr-8">
          <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              Vacuum Truck Services
            </h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              ● Apple Mechanical provides award winning vacuum truck services
              for a wide variety of materials, including solids, sludges, and
              liquids.
            </p>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              ● We work quickly and thoroughly to collect any waste material and
              transport it from your site, leaving your area clean and free.
            </p>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              ● Sustainable disposal practices in compliance with all local,
              provincial, and federal regulations under the Ministry of
              Environment.
            </p>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              ● Preventative maintenance programs for catch basins, storm, and
              sanitary drain systems - ideal for townhouse complex and
              industrial facilities.
            </p>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              ● We also offer a low profile vacuum truck, which specializes in
              underground parking garages and places with low overhead
              requirements.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

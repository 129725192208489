import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
/* This example requires Tailwind CSS v2.0+ */

export default function Features() {
  return (
    <div className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 ">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Here To Save The Day
          </h2>
          <p className="mx-auto mt-3 max-w-3xl text-xl text-gray-500 sm:mt-4">
            Our plumbers are familiar with many different types of plumbing
            systems in commercial properties, production facilities,
            residential, and high-rise properties.
          </p>
        </div>
        {/* First Service */}
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <StaticImage
                src="../images/truck-plumbing.png"
                className="h-48 w-full object-cover"
                alt="Vactor and flushing low profile truck"
              />
            </div>
            <div className="flex flex-1 flex-col justify-between bg-white p-6">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Variety of Options
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Did you know that we also offer a wide variety of vactor and
                  flushing options? Through the use of our combination truck, we
                  can vacuum and utilize high-pressure jet rods to blast away
                  dirt and rocks, to expose pipelines, utilities, and electrical
                  systems…all without any damage! Got a parking garage or other
                  low clearance area? That’s not a problem with our low-profile
                  truck!
                </p>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* First Service */}
          {/* <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3"> */}
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <StaticImage
                src="../images/high-rise.jpg"
                className="h-48 w-full object-cover"
                alt="high-rise commercial residential plumbing"
              />
            </div>
            <div className="flex flex-1 flex-col justify-between bg-white p-6">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Got a tall request?
                </p>
                <p className="mt-3 text-base text-gray-500">
                  We’ve seen it all! Our plumbers also specialize in high-rise
                  services for commercial, residential, and office buildings.
                  High-rise plumbing differs from traditional residential work,
                  as it requires extensive experience, in-depth knowledge, and
                  unique skills. Our team can assist property managers,
                  businesses, and unit owners to identify the best solutions
                  within their budget.
                </p>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* First Service */}
          {/* <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3"> */}
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <StaticImage
                src="../images/complex-system.png"
                className="h-48 w-full object-cover"
                alt="preventative maintenance plans plumbing"
              />
            </div>
            <div className="flex flex-1 flex-col justify-between bg-white p-6">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Complex system?
                </p>
                <p className="mt-3 text-base text-gray-500">
                  We offer preventative maintenance plans. Our plans are
                  tailored to your needs and ensure your stacks, drains, or
                  catch basins are in optimal condition: clear and efficient!
                  Avoid huge bills later and inquire about a maintenance plan
                  with a member of our team
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
